<template>
  <!--begin::Project Listing-->
  <ListingTemplate :customClass="'customer-listing'">
    <template v-slot:body>
      <ListingHeader>
        <template v-slot:content>
          <template>
            <v-col class="pt-0 pb-0 col-md-12 col-lg-12 d-flex">
              <v-flex
                md="2"
                class="mr-2 d-flex justify-content-end"
                style="max-width: 100% !important"
              >
                <label for="" class="font-weight-600 mr-2"> Filter By </label>
                <v-autocomplete
                  hide-details
                  style="max-width: 290px"
                  clearable
                  class="mr-2"
                  v-model.trim="filter_engineer"
                  :items="engineerList"
                  dense
                  flat
                  filled
                  label="Technician"
                  item-color="cyan"
                  color="cyan"
                  solo
                  v-on:click:clear="clearFilter('engineer')"
                  :disabled="pageLoading || engineerLoading"
                  :loading="engineerLoading || pageLoading"
                  item-text="display_name"
                  item-value="id"
                  v-on:change="getRows"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title
                        v-html="'No Technician(s) Found.'"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar
                      height="30"
                      min-width="30"
                      width="30"
                      class="mr-1 my-1 grey lighten-2 grey--text"
                    >
                      <v-img
                        v-if="item.profile_logo != null"
                        src="item.profile_logo"
                      ></v-img>
                      <span v-else class="fw-600 mb-1">{{
                        item.display_name.slice(0, 2).toUpperCase()
                      }}</span>
                    </v-list-item-avatar>
                    <v-list-item-content class="text-ellipsis max-width-400px">
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >{{ item.display_name }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action
                      class="align-self-center"
                      v-if="filter_engineer == item.user"
                    >
                      <v-icon color="cyan">mdi-check</v-icon>
                    </v-list-item-action>
                  </template>
                </v-autocomplete>
                <v-menu
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      filled
                      flat
                      label="Date Range"
                      hide-details
                      solo
                      :loading="pageLoading"
                      :disabled="pageLoading"
                      clearable
                      prepend-inner-icon="mdi-calendar"
                      v-on:click:clear="clearFilter('dates')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :value="formattedDate"
                      color="cyan"
                      style="max-width: 290px"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="cyan"
                    range
                    :disabled="pageLoading"
                    v-on:change="getRows"
                    v-model="dates"
                    :month-picker="true"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-bold-button"
                color="cyan white--text"
                v-on:click="exportVisitReport"
              >
                <v-icon dark left>mdi-database-export</v-icon> Export
              </v-btn>
              <v-menu
                v-if="false"
                max-height="400"
                max-width="250"
                left
                offset-y
                :close-on-content-click="false"
                content-class="white-background"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button btn-tab-hide"
                    color="cyan white--text"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark>mdi-table-edit</v-icon>
                  </v-btn>
                </template>
                <draggable
                  tag="ul"
                  v-model="defaultColDefs"
                  class="draggable-group"
                  handle=".draggable-drag-icon"
                  v-on:change="updateVisible()"
                >
                  <template v-for="cols in defaultColDefs">
                    <li
                      class="draggable-group-item"
                      :key="cols.field"
                      v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                    >
                      <v-checkbox
                        dense
                        v-model="defaultColShow"
                        v-bind:value="cols.field"
                        :label="cols.headerName"
                        :disabled="
                          (Number(cols.fixed) > 0 ? true : false) || dataLoading
                        "
                        color="cyan"
                        hide-details
                        class="mt-0 mb-0"
                        v-on:change="updateVisible()"
                      ></v-checkbox>
                      <v-icon class="draggable-drag-icon" right color="cyan"
                        >mdi-drag</v-icon
                      >
                    </li>
                  </template>
                </draggable>
              </v-menu>
            </v-col>
          </template>
        </template>
      </ListingHeader>
      <ListingSearchCriteria
        v-if="searchEnabled"
        pageModule="visit-overdue-report"
        :basicSearchFields="['display_name', 'visit ']"
        :allowedSearchFields="searchableArray"
      ></ListingSearchCriteria>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 90vh; position: relative"
      >
        <ListingTable
          :columnCount="columnLength()"
          :dataLoading="dataLoading"
          :rowData="rowData"
        >
          <template v-slot:thead>
            <thead>
              <tr>
                <template v-for="cols in customThead">
                  <th
                    v-if="cols.visible"
                    :key="cols.id"
                    class="simple-table-th"
                    :class="{
                      'checkbox-width-limit': cols.checkbox,
                      'pointer-events-none': lodash.isEmpty(rowData),
                    }"
                  >
                    <template
                      v-if="cols.checkbox && Number(cols.checkbox) == 1"
                    >
                      <v-checkbox
                        dense
                        :disabled="dataLoading"
                        v-model="selectedAll"
                        color="cyan"
                        class="hide-margin"
                        hide-details
                      ></v-checkbox>
                    </template>
                    <template v-else>
                      <div
                        v-if="cols.sortable"
                        class="simple-table-text sortable"
                        v-on:click="
                          !dataLoading
                            ? updateSorting({
                                field: cols.field,
                                sort: cols.sort,
                              })
                            : 'javascript:void(0)'
                        "
                      >
                        {{ cols.headerName }}
                      </div>
                      <div v-else class="simple-table-text">
                        {{ cols.headerName }}
                      </div>
                      <template v-if="cols.sort">
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'asc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-ascending</v-icon
                        >
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'desc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-descending</v-icon
                        >
                      </template>
                    </template>
                  </th>
                </template>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody class="custom-border-bottom">
              <template v-if="!lodash.isEmpty(rowData)">
                <tr
                  v-for="(data, index) in rowData"
                  :key="index"
                  link
                  class="timesheet-listing-row"
                >
                  <template v-for="cols in customThead">
                    <td
                      v-if="cols.visible"
                      :key="cols.id"
                      :class="{
                        'simple-table-td': !cols.checkbox,
                        'checkbox-width-limit': cols.checkbox,
                      }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          v-model="selectedRows"
                          v-bind:value="data.visit_id"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else-if="cols.field == 'visit_barcode'">
                        <Barcode
                          :id="data?.visit_id"
                          :barcode="data?.visit_barcode"
                          route="visit.detail"
                        ></Barcode>
                      </template>
                      <template v-else-if="cols.field == 'visit_engineers'">
                        <div class="contract-listing-customer">
                          <p class="m-1 mb-1 custom-nowrap-ellipsis-count">
                            <span
                              v-if="data?.visit_engineers"
                              class="font-weight-700"
                            >
                              {{ data?.visit_engineers }}</span
                            >
                            <em class="text-muted" v-else> no technicians</em>
                          </p>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'equipment_barcode'">
                        <Barcode
                          :id="data?.equipment_id"
                          :barcode="data?.equipment_barcode"
                          route="equipment.detail"
                        ></Barcode>
                      </template>
                      <template
                        v-else-if="cols.field == 'equipment_sales_employee'"
                      >
                        <div class="contract-listing-customer">
                          <p class="m-1 mb-1 custom-nowrap-ellipsis-count">
                            <span
                              v-if="data?.equipment_sales_employee"
                              class="font-weight-700 text-capitalize"
                            >
                              {{ data?.equipment_sales_employee }}</span
                            >
                            <em class="text-muted" v-else> no Sold by SP</em>
                          </p>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'equipment_do_no'">
                        <div class="contract-listing-customer">
                          <p class="m-1 mb-1 custom-nowrap-ellipsis-count">
                            <span
                              v-if="data?.equipment_do_no"
                              class="font-weight-700"
                            >
                              {{ data?.equipment_do_no }}</span
                            >
                            <em class="text-muted" v-else>
                              no delivery number</em
                            >
                          </p>
                        </div>
                      </template>
                      <template
                        v-else-if="cols.field == 'equipment_delivery_date'"
                      >
                        <div class="contract-listing-customer">
                          <p class="m-1 mb-1 custom-nowrap-ellipsis-count">
                            <span
                              v-if="data?.equipment_delivery_date"
                              class="font-weight-700"
                            >
                              {{
                                formatDate(data.equipment_delivery_date)
                              }}</span
                            >
                            <em class="text-muted" v-else> no delivery date</em>
                          </p>
                        </div>
                      </template>
                      <template
                        v-else-if="cols.field == 'customer_company_name'"
                      >
                        <div class="contract-listing-customer">
                          <p class="m-1 mb-1 custom-nowrap-ellipsis-count">
                            <span
                              v-if="data?.customer_company_name"
                              class="font-weight-700 text-capitalize"
                            >
                              {{ data?.customer_company_name }}</span
                            >
                            <em class="text-muted" v-else> no company name</em>
                          </p>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'equipment_brand'">
                        <div class="project-listing-status">
                          <v-chip
                            small
                            text-color=""
                            v-if="data && data.equipment_brand"
                            color="blue"
                            label
                            class="text-capitalize"
                            outlined
                          >
                            {{ data.equipment_brand }}
                          </v-chip>
                          <em class="text-muted" v-else> no item group</em>
                        </div>
                      </template>
                      <template
                        v-else-if="cols.field == 'equipment_description'"
                      >
                        <div class="project-listing-status">
                          <template v-if="data?.equipment_description">
                            <span
                              class="desc_para font-weight-700"
                              @click="
                                showDescription(data.equipment_description)
                              "
                              v-html="truncate(data?.equipment_description, 50)"
                            ></span>
                          </template>

                          <em v-else class="text-muted"> no description</em>
                        </div>
                      </template>
                      <template
                        v-else-if="cols.field == 'equipment_delivery_remark'"
                      >
                        <div class="project-listing-status text-center">
                          <v-icon
                            :color="
                              data?.report_item_remark ? 'green' : '#7e7c7c8a'
                            "
                            @click="
                              showRemark(data.report_item_remark, data.item_id)
                            "
                          >
                            mdi-message-draw</v-icon
                          >
                        </div>
                      </template>
                    </td>
                  </template>
                </tr>
              </template>
              <tr v-else-if="!dataLoading">
                <td colspan="10" class="py-4">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no outstanding visit at the moment.
                  </p>
                </td>
              </tr>
            </tbody>
            <Dialog :commonDialog="desc_dialog" :dialog-width="800">
              <template v-slot:title> Description </template>
              <template v-slot:body>
                <v-container fluid>
                  <div>{{ fullDescription }}</div>
                </v-container>
              </template>

              <template v-slot:action>
                <v-btn
                  depressed
                  class="mx-2 custom-grey-border custom-bold-button"
                  v-on:click="desc_dialog = false"
                >
                  Close
                </v-btn>
              </template>
            </Dialog>
            <Dialog :commonDialog="remark_dialog" :dialog-width="800">
              <template v-slot:title> Remark / Reason </template>
              <template v-slot:body>
                <v-container fluid>
                  <TextAreaField
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    v-model="fullRemark"
                    placeholder="Enter Remark / Reason ..."
                    solo
                    flat
                    row-height="25"
                    counter="500"
                  />
                </v-container>
              </template>

              <template v-slot:action>
                <v-btn
                  depressed
                  class="mx-2 custom-grey-border custom-bold-button"
                  v-on:click="remark_dialog = false"
                >
                  Close
                </v-btn>
                <v-btn
                  :disabled="remarkLoading"
                  :loading="remarkLoading"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  v-on:click="updateItemReportRemark()"
                >
                  Update
                </v-btn>
              </template>
            </Dialog>
          </template>
        </ListingTable>
      </perfect-scrollbar>
      <ListingFooter
        :dataLoading="dataLoading"
        :showingFrom="showingFrom"
        :showingTo="showingTo"
        :totalRows="totalRows"
        :currentPage="currentPage"
        :totalPages="totalPages"
      ></ListingFooter>
    </template>
  </ListingTemplate>

  <!--end::Project Listing-->
</template>

<script>
import { QUERY, PATCH } from "@/core/services/store/request.module";
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import JwtService from "@/core/services/jwt.service";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
import moment from "moment-timezone";
import Dialog from "@/view/pages/partials/Dialog.vue";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "visit-overdue-report",
  data() {
    return {
      pageTips: false,
      customerDialog: false,
      engineerList: [],
      customerPropertyDialog: false,
      exportLoading: false,
      pageModule: "visit-overdue-report",
      routeAPI: "visit-reports",
      routeName: "outstanding-visit",
      status: "all",
      engineerLoading: false,
      pageLoading: false,
      file: "",
      importDialog: false,
      report_type: "outstaning-visit",
      statusList: [],
      customThead: [],
      fullDescription: null,
      desc_dialog: false,
      fullRemark: null,
      remark_dialog: false,
      remarkLoading: false,
      item_id: 0,
    };
  },
  components: {
    draggable,
    Barcode,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
    TextAreaField,
  },

  methods: {
    showDescription(description) {
      this.fullDescription = description;
      this.desc_dialog = true;
    },
    showRemark(remark, id) {
      this.fullRemark = remark;
      this.remark_dialog = true;
      this.item_id = id;
    },
    truncate(text, length) {
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
    updateItemReportRemark() {
      const _this = this;
      _this.remarkLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: `update-item-remark/${this.item_id}`,
          data: {
            remark: this.fullRemark,
          },
        })
        .then(() => {
          _this.remarkLoading = false;
          _this.remark_dialog = false;
          _this.getRows();
        })
        .catch((error) => {
          _this.remarkLoading = false;
          _this.logError(error);
        })
        .finally(() => {
          _this.getRows();
        });
    },
    exportVisitReport() {
      const { query } = this.$route;

      let downloadURL =
        process.env.VUE_APP_API_URL + "visit-overdue-report/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      if (query) {
        this.lodash.map(query, (row, index) => {
          if (row) {
            downloadURL.searchParams.append(`${index}`, row);
          }
        });
      }
      if (this.dates && this.dates[0] && this.dates[1]) {
        if (this.dates && this.dates[0]) {
          downloadURL.searchParams.append("daterange[0]", this.dates[0]);
        }
        if (this.dates && this.dates[1]) {
          downloadURL.searchParams.append("daterange[1]", this.dates[1]);
        }
      }
      if (this.report_type) {
        downloadURL.searchParams.append("visit_report_type", this.report_type);
      }
      if (this.filter_engineer) {
        downloadURL.searchParams.append(
          "filter_engineer",
          this.filter_engineer
        );
      }

      window.open(downloadURL, "_blank");
    },

    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "engineer":
          _this.filter_engineer = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getRows();
    },

    getEngineers() {
      const _this = this;
      _this.engineerLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "engineer-list",
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              _this.engineerLoading = false;
              reject(error);
            })
            .finally(() => {
              _this.engineerLoading = false;
            });
        } catch (error) {
          _this.logError(error);
          _this.engineerLoading = false;
          reject(error);
        }
      });
    },
    getEngineerList() {
      const _this = this;

      _this
        .getEngineers()
        .then((engineer) => {
          _this.engineerList = engineer;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Outstanding Commissioning Job",
      },
    ]);
    this.getEngineerList();
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.status = _this.$route.query.status || _this.status;

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
<style scoped>
/* .main-listing-page {
  border-top: 0 !important;
} */
.desc_para {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
</style>
